<template>
  <LayoutBox :loading="loading" title="网点客流统计">
    <div slot="layoutBox-right" style="width: 120px">
      <Select
        v-model="queryParam.flowType"
        box-shadow
        :allow-clear="false"
        custom
        :replace-fields="{
          lable: 'msg',
          value: 'code',
        }"
        :options="customerFlow"
        placeholder="请选择周期"
      ></Select>
    </div>
    <div ref="lineRef" style="height: 100%"></div>
  </LayoutBox>
</template>

<script>
import { mapState } from 'vuex';
import { Column } from '@antv/g2plot';
import LayoutBox from '@/views/screen/components/LayoutBox.vue';
export default {
  //组件注册
  components: {
    LayoutBox,
    Select: () => import('@/views/screen/components/Select.vue'),
  },
  //组件传值
  props: {
    globalQuery: {
      type: Object,
      default: () => ({}),
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      queryParam: {},
      replaceFields: {
        xField: 'text',
        yField: 'count',
      },
      lineChart: null,
    };
  },
  //计算属性
  computed: {
    ...mapState({
      customerFlow: (state) =>
        state.screenStore.commondict['MonitorDataCustomerFlowEnum']
          ? state.screenStore.commondict['MonitorDataCustomerFlowEnum']
          : [],
    }),
  },
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler: 'getData',
    },
    globalQuery: {
      deep: true,
      handler: 'getData',
    },
    refreshKey: {
      handler: 'getData',
    },
  },
  //DOM访问
  mounted() {
    this.lineChart = new Column(this.$refs.lineRef, {
      appendPadding: [8, 0, 0, 0],
      data: [],
      ...this.replaceFields,
      color: 'l(90) 0:#25E3FF 1:#0F57A1',
      xAxis: {
        label: {
          style: {
            fill: '#DEF8FF',
          },
        },
        tickLine: null,
        line: {
          style: {
            stroke: '#20579B',
            lineWidth: 1,
            opacity: 0.7,
            lineDash: [4, 5],
          },
        },
      },
      yAxis: {
        min: 1,
        title: {
          position: 'end',
          autoRotate: false,
          text: '人',
          style: {
            fill: '#DEF8FF',
          },
        },
        label: {
          style: {
            fill: '#DEF8FF',
          },
        },
        grid: {
          line: {
            style: {
              stroke: '#20579B',
              lineWidth: 1,
              opacity: 0.7,
              lineDash: [4, 5],
            },
          },
        },
      },
      tooltip: {
        showTitle: false,
        formatter: (column) => ({
          name: column[this.replaceFields.xField],
          value: `${column[this.replaceFields.yField]}人`,
        }),
      },
    });
    this.lineChart.render();
    this.queryParam = {
      flowType: this.customerFlow[0].code,
    };
  },
  //保存方法
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/hall/flow-stat`,
          {
            ...this.globalQuery,
            ...this.queryParam,
          }
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        this.lineChart.changeData(data.data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
